.histogram {
  position: relative;
  display: flex;
  height: 70px;
  margin-top: 35px;
  margin-bottom: 3px;

  > .histogram-bar {
    position: absolute;
    background-color: #e3e2e2;
    bottom: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    &.selected {
      background-color: gray;
    }
  }
}

.no-thr-box {
  padding: 15px;
  background-color: whitesmoke;
  text-align: center;
  border-radius: 5px;
  margin-top: 35px;
  color: gray;
}
