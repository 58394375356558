.gallery {
  background-color: whitesmoke;
  border-radius: 5px;
  margin-top: 35px;
  position: relative;

  > .counter {
    background: rgba(0, 0, 0, 0.7);
    color: white;
    position: absolute;
    left: 15px;
    top: 15px;
    padding: 5px 10px;
    border-radius: 5px;
  }

  > .gallery-preview {
    display: block;
    width: 100%;
    object-fit: contain;
    padding: 7px;
    box-sizing: border-box;
    max-height: 600px;
  }

  > .thumbnails {
    display: flex;
    overflow-x: auto;
    align-items: center;
    gap: 5px;
    padding: 7px;

    > .thumbnail {
      position: relative;
      flex-shrink: 0;
      height: 100px;
      border: 1px solid white;
      border-radius: 5px;
      cursor: pointer;

      &.selected {
        border: 1px solid black;
      }

      > img {
        height: 100%;
        border-radius: 5px;
      }

      > .ignore-button {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 20px;
        height: 20px;
        background-color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: black;
        cursor: pointer;

        & .ignored {
          background-color: red;
          color: white;
        }
      }
    }
  }
}
