.page {
  border-top: 5px solid #4caf50;
  height: 100vh;
  box-sizing: border-box;

  &.dragging {
    opacity: 0.6;
  }

  > .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    position: relative;

    > a {
      text-decoration: none;

      > .logo {
        font-family: 'Roboto', sans-serif;
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 30px;
        margin-top: 10px;

        > .logo-slide {
          color: #4caf50;
        }

        > .logo-extract {
          color: black;
        }
      }
    }

    > .step {
      text-align: justify;
      //transition: opacity 1s ease-in-out;
      //opacity: 1;

      &.active {
        > h2 {
          margin-top: 60px;
          color: black;

          > .step-no {
            background-color: #4caf50;
          }
        }

        > div {
          display: block;
        }
      }

      > h2 {
        margin-top: 40px;
        margin-bottom: 25px;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        display: flex;
        align-items: center;
        color: lightgray;

        > .step-no {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          background-color: lightgray;
          color: white;
          border-radius: 50%;
          font-size: 16px;
          margin-right: 15px;
        }
      }

      > div {
        display: none;

        font-size: 1rem;
        font-weight: 400;
        color: #333;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
      }
    }
  }
}
