.upload-container {
  text-align: center;
  margin-top: 35px;

  > input[type="file"] {
    display: none;
  }

  > .action-button {
    display: block;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 5px;
    box-sizing: border-box;

    &.initial {
      color: white;
      background-color: #4caf50;

      &:hover {
        background-color: #45a049;
      }

      &:active {
        background-color: #337336;
      }
    }

    &.processing {
      background-color: lightgray;
      cursor: wait;
    }
  }

  > span {
    font-size: 14px;
    color: gray;
    margin-bottom: 15px;
  }
}

.iphone-box {
  padding: 15px;
  background-color: whitesmoke;
  text-align: center;
  border-radius: 5px;
  margin-top: 35px;
  color: gray;
}
