.download-button {
  display: block;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  padding: 15px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 35px;
  margin-bottom: 50px;
  box-sizing: border-box;

  &.active {
    background-color: #4caf50;
    color: white;

    &:hover {
      background-color: #45a049;
    }

    &:active {
      background-color: #337336;
    }
  }

  &.processing {
    background-color: lightgray;
    cursor: wait;
  }
}
